import React from 'react';
import { motion } from 'framer-motion';
import { animation } from '../../../utils/framerVariants';

const GeneralHex = () => {
	return (
		<svg viewBox="0 0 982 923" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 0, -40, 0 ], x: [ -10, -100, 0 ] } )}
					d="M100.959 318.369L200.959 376.104V491.574L100.959 549.309L0.958984 491.574V376.104L100.959 318.369Z" fill="#95D5FF"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 0, 40, 0 ], x: [ -20, 100, 0 ] } )}
					d="M100.959 601.004L200.959 658.739V774.209L100.959 831.944L0.958984 774.209V658.739L100.959 601.004Z" fill="#093C7D"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 0, -60, 0 ], x: [ -30, 50, 0 ] } )}
					d="M507.553 691.865L607.553 749.6V865.07L507.553 922.805L407.553 865.07V749.6L507.553 691.865Z" fill="#29A7E1"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 30, -40, 0 ], x: [ 10, -100, 0 ] } )}
					d="M841.805 433.839L941.805 491.574V607.044L841.805 664.779L741.805 607.044V491.574L841.805 433.839Z" fill="#95D5FF"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 10, -50, 0 ], x: [ 20, 140, 0 ] } )}
					d="M580.959 40.2808L680.959 98.0158V213.486L580.959 271.221L480.959 213.486V98.0158L580.959 40.2808Z" fill="#0299FC"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 10, -40, 0 ], x: [ 30, -100, 0 ] } )}
					d="M337.625 87.4292L437.625 145.164V260.634L337.625 318.369L237.625 260.634V145.164L337.625 87.4292Z" fill="#07345E"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 0, -40, 0 ], x: [ 0, 110, 0 ] } )}
					d="M480.959 189.985L680.959 305.455V536.395L480.959 651.865L280.959 536.395V305.455L480.959 189.985Z" fill="#0C52AB"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 0, -50, 0 ], x: [ 0, -140, 0 ] } )}
					d="M41.959 336.682L140.959 279.524L239.959 336.682V450.997L140.959 508.155L41.959 450.997V336.682Z" stroke="black" strokeWidth="2"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 20, -40, 0 ], x: [ -20, 100, 0 ] } )}
					d="M41.959 619.316L140.959 562.159L239.959 619.316V733.632L140.959 790.789L41.959 733.632V619.316Z" stroke="black" strokeWidth="2"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 0, -40, 0 ], x: [ 30, -130, 0 ] } )}
					d="M448.553 710.178L547.553 653.02L646.553 710.178V824.493L547.553 881.651L448.553 824.493V710.178Z" stroke="black" strokeWidth="2"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 50, -40, 0 ], x: [ 0, 120, 0 ] } )}
					d="M782.805 452.151L881.805 394.994L980.805 452.151V566.467L881.805 623.624L782.805 566.467V452.151Z" stroke="black" strokeWidth="2"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 100, -30, 0 ], x: [ 0, -110, 0 ] } )}
					d="M521.959 58.5931L620.959 1.43546L719.959 58.5931V172.909L620.959 230.066L521.959 172.909V58.5931Z" stroke="black" strokeWidth="2"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 200, -20, 0 ], x: [ 0, 100, 0 ] } )}
					d="M278.625 105.742L377.625 48.5839L476.625 105.742V220.057L377.625 277.215L278.625 220.057V105.742Z" stroke="black" strokeWidth="2"
				/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
				<motion.path
					animate={() => animation( { scale: [ 1, 1.01, 1.03, 1 ], y: [ 150, -10, 0 ], x: [ 0, -90, 0 ] } )}
					d="M321.959 266.032L520.959 151.14L719.959 266.032V495.818L520.959 610.71L321.959 495.818V266.032Z" stroke="black" strokeWidth="2"
				/>
			</g>
		</svg>
	);
};

export default GeneralHex;
