import React from 'react';
import PropTypes from 'prop-types';
import MarkdownRenderer from '../../../components/MarkdownRenderer';

import Layout from '../../../components/Layout';
import GeneralHex from '../../../components/Hero/General/GeneralHex';

const PolicyTemplate = ( { title, copy, heading } ) => (
	<Layout title={ title }>
		<main>
			<section className="policy">
				<GeneralHex />
				<div className="policy-copy">
					<h1>{ heading }</h1>
					<MarkdownRenderer copy={ copy } />
				</div>
			</section>
		</main>
	</Layout>
);

PolicyTemplate.propTypes = {
	title: PropTypes.string,
	copy: PropTypes.string,
	heading: PropTypes.string
};

export default PolicyTemplate;
